@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.1rem !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace !important;
  font-size: 1rem !important;
}

.main-container {
  position: relative;
  // background-color: #0d1116;
  background-color: #0a101d;

}

a {
  text-decoration: none;
}

a:hover {
  color: #3c7ee8;
  opacity: .9;
  transform: scale(1.1);
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

button:hover {
  opacity: .8;
  transform: scale(1.05);
  transition: all .15s ease-in-out;
}

p, a, h1, h2, h3, h4, h5, label, button {
  color: white;
  font-family: "Lato", sans-serif;
}

.navigation-bar {
  justify-content: space-between;
  background-color: #0d1116;

  button {
    font-size: 1.1rem;
    font-family: "Lato", sans-serif;
    text-transform: initial;
    padding: 6px 10px;
  }

  svg {
    cursor: pointer;
  }
}

.navigation-bar-responsive {
  span, p {
    color: #0d1116;
    font-family: "Lato", sans-serif;
  }
}

.container {
  margin-top: 64px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header-image {
  width: 100%;
  height: 450px;

  img {
    width: 100%;
    height: 450px;
    object-fit: cover;
  }
}

.mobile-menu-top {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-weight: bold;
}

.items-container {
  padding: 5% 10%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.light-mode {
  background-color: #f8f9fa;

  .navigation-bar {
    background-color: #f8f9fa;
  }

  p, a, h1, h2, h3, h4, h5, label, button {
    color: #0d1116;
  }

  a:hover {
    color: #5000ca;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
  }

  .navigation-bar svg {
    color: #0d1116;
  }

  .about-section {
    background-image: url("./assets/images/bg-light.png");
  }

  .skills-container svg {
    color: #0d1116;
  }

  .chip {
    background-color: rgba(0, 0, 0, 0.08);
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }

  .vertical-timeline::before {
    background-color: rgba(0, 0, 0, 0.08);
  }

  .vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }

  .vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
    color: gray;
  }

  .vertical-timeline-element-content-arrow {
    border-right: 7px solid rgba(0, 0, 0, 0.08) !important;
  }

  .flex-chips .chip-title {
    color: #0d1116;
  }
}

.invisible{
  opacity: 0 !important;
}

@keyframes moveFullHand {
  0% {
    transform: matrix(0.9899987707138062, 0.14792372286319733, -0.14792372286319733, 0.9889987707138062, 27.73773193359375, -32.9647216796875);
  }
  50% {
    transform: matrix(0.9889987707138062, 0.14792372286319733, -0.16792372286319733, 0.9989987707138062, 28.73773193359375, -38.9647216796875); /* Move up */
  }
  100% {
    transform: matrix(0.9889987707138062, 0.14792372286319733, -0.14792372286319733, 0.9889987707138062, 27.73773193359375, -35.9647216796875);
  }
}

.fullHand {
  animation: moveFullHand 1s ease-in-out infinite;
}

@keyframes moveFullHand2 {
  0% {
    transform: matrix(1, 0, 0, 1, -0.56622314453125, -1.1324462890625);
  }
  50% {
    transform: matrix(1, 0, 0, 1, -0.56622314453125, 1.0324462890625); /* Move up */
  }
  100% {
    transform: matrix(1, 0, 0, 1, -0.56622314453125, -1.1324462890625);
  }
}

.fullHand2 {
  animation: moveFullHand2 1s ease-in-out infinite;
}

.typingText {
  display: inline; /* Keep it inline for SVG */
}

.typingText {
  display: inline; /* Keep it inline for SVG */
}

.typingText tspan {
  opacity: 0; /* Start with characters hidden */
  animation: typing 12s steps(1, end) infinite; /* Adjust duration as needed */
}

@keyframes typing {
  0%, 20% {
      opacity: 0; /* Start hidden */
  }
  25%, 45% {
      opacity: 1; /* Fade in */
  }
  80% {
      opacity: 1; /* Keep visible before clearing */
  }
  85%, 100% {
      opacity: 0; /* Clear all characters */
  }
}

/* Apply reverse clearing effect for each character */
.typingText tspan:nth-child(1) { animation-delay: 0s; }
.typingText tspan:nth-child(2) { animation-delay: 1.4s; }
.typingText tspan:nth-child(3) { animation-delay: 1.8s; }
.typingText tspan:nth-child(4) { animation-delay: 2.2s; }
.typingText tspan:nth-child(5) { animation-delay: 2.6s; }
.typingText tspan:nth-child(6) { animation-delay: 3s; }
.typingText tspan:nth-child(7) { animation-delay: 3.4s; }
.typingText tspan:nth-child(8) { animation-delay: 3.8s; }
.typingText tspan:nth-child(9) { animation-delay: 4.2s; }
.typingText tspan:nth-child(10) { animation-delay: 5.6s; }

@keyframes clearing {
  0%, 20% {
      opacity: 1; /* Start visible */
  }
  60%, 100% {
      opacity: 0; /* Clear characters */
  }
}


@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}




@media screen {
  @media (max-width: 768px) {
    .items-container {
      padding: 5% 5%;
    }
  }
}
