.contact_wrapper svg {
  font-size: 1em;
  vertical-align: middle;
  margin-right: 5px;
}

.contact-form {
  padding-top: 20px;

  // button {
  //   float: right;
  //   background-color: white;
  //   color: #050f0b;
  // }

  // button:hover {
  //   background-color: #5000ca;
  //   color: white;
  // }

  fieldset {
    background-color: white;
  }
}

label {
  font-size: 1.2rem;
  font-family: 'DomaineDispNar-Medium', sans-serif;
}

.form-flex {
  display: flex;
  gap: 15px;
  padding-bottom: 15px;
}

.form-flex .MuiFormControl-root {
  width: 100%;
}

form {
  width: 100% !important;
}

.body-form {
  width: 100% !important;
  margin-bottom: 15px !important;
}